<template>
	<div>
		<!-- 公共头部 -->
<Header></Header>
		<div class="section1">
			<img class="icon" :src="orderInfo.pay_status == 0 ? require('@/assets/images/icon32.png') : require('@/assets/images/icon31.png') " />
			<div class="info" v-if="orderInfo.pay_status == 0">您选择的“{{orderInfo.subject}}”<br/>未支付成功，报名失败！</div>
			<div class="info" v-if="orderInfo.pay_status == 1">恭喜您，您选择的“{{orderInfo.subject}}”<br/>已报名成功！</div>
		</div>
		<div class="section2">
			<router-link :to="'/pay_info?order=' + orderInfo.order" replace class="btn" v-if="orderInfo.pay_status == 0">重新缴费</router-link>
			<router-link :to="'/dataUpload/upload?id=' + orderInfo.id + '&title=' + orderInfo.subject" class="btn" v-if="orderInfo.pay_status == 1">上传资料</router-link>
			<router-link to="/index" class="btn btn1">返回首页</router-link>
		</div>
		<div v-if="orderInfo.pay_status == 1"  class="section3">
			<img src="@/assets/images/liucheng.png" alt="">
		</div>
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				ouid:'',
				orderInfo: ''
			}
		},
		computed: {
			uid() {
				return this.ouid?this.ouid:this.$store.state.uid;;
			}
		},
		activated() {
			// this.ouid = this.$route.query.ouid
			// this.getUserInfo();

		},
		created(){
			this.ouid =this.$route.query.ouid;
			this.getUserInfo();
		},
		deactivated() {
			this.orderInfo = '';
		},
		methods: {
			// 获取订单详情
			async getOrderInfo() {
				var uid = this.uid;
				var order = this.$route.query.order;
				var data = {
					uid, order
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/order/order', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('个人报名 订单状态详情：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.orderInfo = res.data;
				} else {
					this.$messageBox.alert(res.data, '温馨提示');
				}
			},
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
							wx.miniProgram.getEnv(function(res){
      			 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return
					}
					wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getOrderInfo();
				}
			},
		},
	};
</script>

<style scoped>
	.section1{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 1.8rem;
		padding-bottom: 1.3rem;
	}
	.section1 .icon{
		width: 1.2rem;
		height: 1.2rem;
	}
	.section1 .info{
		font-size: 0.28rem;
		color: #333333;
		line-height: 0.42rem;
		text-align: center;
		margin-top: 0.6rem;
	}
	
	.section2{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.section2 .btn{
		width: 5.8rem;
		height: 0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0.5rem;
		color: #ffffff;
		background: #0372c7;
	}
	.section2 .btn1{
		background: #d4d4d4;
		margin-top: 0.6rem;
	}
	.section3{
		position: absolute;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 30px 0;
	}
	.section3 img{
	width: 90%;
	object-fit: contain;
	}
	
</style>